import React from 'react'
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
//import HistoryIcon from '@material-ui/icons/History'
import HomeIcon from '@material-ui/icons/Home'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MailIcon from '@material-ui/icons/Mail'
import MenuIcon from '@material-ui/icons/Menu'
//import SettingsIcon from '@material-ui/icons/Settings'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import NextLink from 'next/link'

import ConstantIcon from './ConstantIcon'
import FunctionIcon from './FunctionIcon'
import VariableIcon from './VariableIcon'


const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'appBar': {
      marginLeft: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    'appBarContactLinks': {
      [theme.breakpoints.down(700)]: {
        display: 'none',
      },
    },
    'appBarName': {
      flexGrow: 1,
      [theme.breakpoints.down(380)]: {
        fontSize: '1.0rem',
      },
    },
    'drawer': {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    'drawerPaper': {
      width: drawerWidth,
    },
    'menuButton': {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    'toolbar': theme.mixins.toolbar,
  }),
)

export default function MyAppBar(props: any) {
  const { container } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />

      <Divider />

      <List onClick={() => { setMobileOpen(false) }}>
        <NextLink href='/'>
          <ListItem button key='Homepage'>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary='Homepage' />
          </ListItem>
        </NextLink>

        {/*

        <Divider />

        <NextLink href='/histroy'>
          <ListItem button key='History'>
            <ListItemIcon><HistoryIcon /></ListItemIcon>
            <ListItemText primary='History' />
          </ListItem>
        </NextLink>

        <Divider />

        */}

        <NextLink href='/functions'>
          <ListItem button key='Functions'>
            <ListItemIcon><FunctionIcon /></ListItemIcon>
            <ListItemText primary='Functions' />
          </ListItem>
        </NextLink>

        <NextLink href='/constants'>
          <ListItem button key='Constants'>
            <ListItemIcon><ConstantIcon /></ListItemIcon>
            <ListItemText primary='Constants' />
          </ListItem>
        </NextLink>

        <NextLink href='/variables'>
          <ListItem button key='Variables'>
            <ListItemIcon><VariableIcon /></ListItemIcon>
            <ListItemText primary='Variables' />
          </ListItem>
        </NextLink>

        {/*

        <Divider />

        <NextLink href='/settings'>
          <ListItem button key='Settings'>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary='Settings' />
          </ListItem>
        </NextLink>

        <Divider />

        */}

        <Link href='mailto:info@calctree.de' color="inherit" underline="none" aria-label='Mail'>
          <ListItem button key='Mail'>
            <ListItemIcon><MailIcon /></ListItemIcon>
            <ListItemText primary='Mail' />
          </ListItem>
        </Link>
      </List>

    </div>
  )

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label='Open Drawer'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" component="h1" color="inherit" className={classes.appBarName}>
            calctree.de
          </Typography>

          <Link color="inherit" href='mailto:info@calctree.de' underline="none" aria-label='Mail'>
            <Tooltip title='Mail' className={classes.appBarContactLinks}>
              <IconButton color="inherit">
                <MailIcon />
              </IconButton>
            </Tooltip>
          </Link>

        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label='Site Menu'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}
