import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

// @see: https://materialdesignicons.com/icon/alpha-c-box-outline

export default function ConstantIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M5,5V19H19V5H5M11,7H13A2,2 0 0,1 15,9V10H13V9H11V15H13V14H15V15A2,2 0 0,1 13,17H11A2,2 0 0,1 9,15V9A2,2 0 0,1 11,7Z" />
    </SvgIcon>
  )
}
